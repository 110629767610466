<template>
  <div>
  <v-card
    class="ma-auto"
    max-width="500"
  >
    <v-card-title
      class="headline grey lighten-2 subtitle-1"
      primary-title
    >
      <span> Play </span>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="urlstr"
        hint="Youtube contents URL or its last 11 digit ID"
        label="URL or Contents ID"
      ></v-text-field>
      {{contents}}
    </v-card-text>

    <v-card-text>
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Control
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Control />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <v-card-actions>
      <v-btn @click='urlstr=""'><v-icon>mdi-eraser</v-icon>reset</v-btn><!-- Reset Button -->
      <!-- Floating Action Button -->
      <v-btn
        absolute
        dark
        fab
        bottom
        right
        color="primary"
        @click="play"
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>

  </div>
</template>

<script>
  import Control from '@/components/Control'
  import axios from 'axios'
  var sprintf = require('sprintf-js').sprintf

  export default {
    components: {
      Control,
    },
    data: () => ({
      urlstr: "",
    }),
    methods: {
      play: async function(contents){
        console.log("play", contents)
        const url = sprintf("/play/%s/00:00:00", this.contents)
        const res = await axios.get(url)
        console.log("res", res)
      },
    },
    computed: {
      contents: function(){
        let arrayMatch = this.urlstr.match(/v=([^&]*)/)
        if (arrayMatch){
          console.log("arrayMatch", arrayMatch)
          return arrayMatch[1]
        } else {
          console.log("contents", this.urlstr)
          return this.urlstr
        }
      }
    }
  }
</script>