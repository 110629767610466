<template>
  <v-app>

    <Navbar titleStr="Home Station"  :links="links">
      <template v-slot:menu>
        <v-spacer></v-spacer>
        <v-btn text class="grey--text" v-if="$route.name != 'home'" @click="$router.back()">
          <v-icon>arrow_back</v-icon><span>Back</span>
        </v-btn>
      </template>
    </Navbar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer 
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          color="grey"
          text
          class="my-2"
        >
          <v-badge
            dot
          >
            <v-icon>
              mdi-email
            </v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          color="grey"
          text
          class="my-2"
        >
          <strong>Atelier UEDA</strong>
        </v-btn>
        <v-btn
          color="grey"
          text
          class="my-2"
        >
          <v-badge
            dot
          >
            <v-icon>
              mdi-update
            </v-icon>
          </v-badge>
        </v-btn>
      </v-row>
    </v-footer>

    <SnackBar/>

  </v-app>
</template>

<script>
import {Navbar} from 'vuetify-nav'
import Websocket from '@/mixins/websoket'
import SnackBar from '@/components/SnackBar.vue'

export default {
  name: 'App',
  components: { Navbar, SnackBar},
  mixins: [Websocket],
  data: function(){
    if (this.$fromHS2){
      return {
        links: [
          { icon: 'home', text: 'Home', route: '/channel2/home'},
//          { icon: 'home', text: 'Home', route: '/channel/home'},
          { icon: 'mdi-play', text: 'Play', route: '/play'},
          { icon: 'mdi-remote-tv', text: 'Control', route: '/control'},
          { icon: 'mdi-cog', text: 'Settings', route: '/settings'},
          { icon: 'mdi-cog', text: 'SetHome', route: '/sethome'},
        ]
      }
    } else {
      return {
        snackbar: true,
      snackbar_message: "あほ",

        links: [
          { icon: 'home', text: 'Home', route: '/channel2/home'}, 
          { icon: 'mdi-cog', text: 'SetHome', route: '/sethome'},
        ]
      }
    }
  },
  mounted(){
    if (this.$fromHS2){
      this.wsStart() // in websocket mixin
    }
  }
};
</script>
